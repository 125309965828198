/*
    Úvodní layout administrace
*/
import React,{ Component,useEffect } from 'react';
import Header from './Header';
import Notification from '../GlobalComponents/Notification';
import {Switch, Route } from 'react-router-dom';
import withAdminAuthentication from '../Modules/Auth/Components/WithAuthentication';
import ChangePassword from '../Modules/Auth/Components/ChangePassword';
import Video from '../Modules/Video/Components/Video';
import { useQuery } from 'react-apollo';
import { GET_SETTINGS } from '../GlobalQueries/globalQueries';

const Layout = (props) => {
	
	const {data:settingsData} = useQuery(GET_SETTINGS,{
		fetchPolicy: 'network-only',
		variables:{
		  refresh: props.location.pathname
		}
	});

	useEffect(() => {

		if(settingsData && settingsData.settings)
		{
			if(settingsData.settings.closedPortal == 1)
			{
				window.location.href="/";
			} 
		}
		  
	},[settingsData])
	  	
    return (
	   
    	<div className="wholeContent">			       
 	      
 	        <div id="layout">	
				<Header />  
				<Switch>
        
					<Route exact path='/video' component={Video}></Route>
					<Route exact path='/settings/change-password' component={ChangePassword}></Route>
					<Route path='/:category/:video' component={Video}></Route>
					<Route path='/v/:video' component={Video}></Route>
					

				</Switch>
				<Notification />
			</div>
		     	       
		</div>
   
    );
  
}

export default withAdminAuthentication(Layout);
